import './index.less';
import {CSSProperties, memo, useState} from "react";
import {Popover} from '../Popover/Popover';

interface ItemProps {
  defaultImage: string;
  hoverImage: string
  style?: CSSProperties;
  className?: string;
  onClick?: () => void;
}

export const Item = memo(function Item(props: ItemProps) {
  const {
    defaultImage,
    hoverImage,
    style,
    className,
    onClick,
  } = props;
  const [status, setStatus] = useState(defaultImage);
  return (
    <div
      style={{display: "flex", position: "absolute", ...style}}
      className={className}
      onMouseOver={() => {
        setStatus(hoverImage);

      }}
      onMouseOut={() => {
        setStatus(defaultImage);
      }}
      onClick={onClick}
    >
      <img className='item' src={status} alt=""/>
    </div>
  );
});

export default function PageThree() {
  return (
    <div className='pageThree'>
      <img className='img' src={require('../../assets/pageThree/title.png')} alt=""/>
      <div className='content'>
        {/*<img className='img' src={require('../../assets/pageThree/1.png')} alt=""/>*/}
        <div className='content-body'>
          <div className='one'>
            <Popover
              position='top'
              content={
                <div style={{fontSize: '0.14rem'}}>
                  参与测试任务的人员，使用TangGo测试平台进行测试，通过协同测试平台客户端进行分工协作、提交和共享数据
                </div>
              }
            >
              <Item
                style={{
                  top: '1.49rem',
                  left: '0.46rem',
                }}
                defaultImage={require('../../assets/pageThree/participant.png')}
                hoverImage={require('../../assets/pageThree/participant_h.png')}
              />
            </Popover>

          </div>
          <div className='two'>
            <Popover
              position='top'
              content={
                <div style={{fontSize: '0.14rem'}}>
                  管理员通过协同测试平台服务端下发任务，对任务和人员进行管理调度。
                </div>
              }>
              <Item
                style={{top: '-0.05rem', left: '1.26rem'}}
                defaultImage={require('../../assets/pageThree/admin.png')}
                hoverImage={require('../../assets/pageThree/admin_h.png')}
              />
            </Popover>
            <Popover position='top' content={
              <div style={{fontSize: '0.14rem'}}>
                本地运行的服务框架，工具运行在本机，亦可离线使用，含信息收集、弱点扫描、漏洞测试、测试辅助工具等四大类40余种安全测试工具。
              </div>
            }>

              <Item
                style={{
                  left: '0.18rem',
                  top: '1.49rem',
                }}
                defaultImage={require('../../assets/pageThree/本地服务框架-默认@2x.png')}
                hoverImage={require('../../assets/pageThree/本地服务框架-悬停@2x.png')}
              />
            </Popover>
            <Popover position='top' content={
              <div style={{fontSize: '0.14rem'}}>
                协同测试平台客户端用于团队之间任务分工，同步、汇总和共享数据。它负责各工具的数据收集、处理和推送数据到协同测试平台服务端，支持内网环境部署的协同平台服务端数据推送，支持以插件的形式接入第三方工具的数据。
              </div>
            }>

              <Item
                style={{
                  top: '1.49rem',
                  right: '0.38rem',
                }}
                defaultImage={require('../../assets/pageThree/协同客户端-默认.png')}
                hoverImage={require('../../assets/pageThree/协同客户端-悬停.png')}
              />
            </Popover>
            <Popover position='top' content={
              <div style={{fontSize: '0.14rem'}}>
                远程部署的服务框架，工具运行在远程服务器上，界面与工具以分离的模式运行。
              </div>
            }>
              <Item
                style={{
                  bottom: '1.78rem',
                  left: '1.26rem',
                }}
                defaultImage={require('../../assets/pageThree/远程服务框架-默认@2x.png')}
                hoverImage={require('../../assets/pageThree/远程服务框架-悬停@2x.png')}
              />
            </Popover>
          </div>
          <div className='three'>
            <Popover position='top' content={
              <div style={{fontSize: '0.14rem'}}>
                管理员可以对测试人员和组织进行管理，支持多级分组和角色划分。
              </div>
            }>
              <Item
                style={{
                  top: '0',
                  left: '0.62rem',
                }}
                defaultImage={require('../../assets/pageThree/人员管理-默认.png')}
                hoverImage={require('../../assets/pageThree/人员管理-悬停.png')}
              />
            </Popover>
            <Popover position='top' content={
              <div style={{fontSize: '0.14rem'}}>
                管理员可以对任务进行下发、管理调度。
              </div>
            }>
              <Item
                style={{
                  top: '0',
                  left: '3.3rem',
                }}
                defaultImage={require('../../assets/pageThree/任务管理-默认@2x.png')}
                hoverImage={require('../../assets/pageThree/任务管理-悬停@2x.png')}
              />
            </Popover>
            <Popover position='top' content={
              <div style={{fontSize: '0.14rem'}}>
                管理员能够创建任务，任务信息将通过协同测试平台同步给任务参与人。
              </div>
            }>
              <Item
                style={{
                  top: '0',
                  right: '0.38rem',
                }}
                defaultImage={require('../../assets/pageThree/任务信息-默认@2x.png')}
                hoverImage={require('../../assets/pageThree/任务信息-悬停@2x.png')}
              />
            </Popover>
            <Popover position='top' content={
              <div style={{fontSize: '0.14rem'}}>
                测试数据通过协同测试平台客户端推送到协同测试平台服务端，进行数据清洗。它支持创建数据处理规则或执行自定义清洗脚本的方式清洗数据。
              </div>
            }>
              <Item
                style={{
                  top: '1.37rem',
                  left: '0.62rem',
                }}
                defaultImage={require('../../assets/pageThree/数据清洗-默认.png')}
                hoverImage={require('../../assets/pageThree/数据清洗-悬停.png')}
              />
            </Popover>
            <Popover position='top' content={
              <div style={{fontSize: '0.14rem'}}>
                清洗后的数据进行数据解析器对数据进行格式化、分类、关联和存储。
              </div>
            }>
              <Item
                style={{
                  top: '1.37rem',
                  left: '3.3rem',
                }}
                defaultImage={require('../../assets/pageThree/数据解析-默认.png')}
                hoverImage={require('../../assets/pageThree/数据解析-悬停.png')}
              />
            </Popover>
            <Popover position='top' content={
              <div style={{fontSize: '0.14rem'}}>
                多维度展示测试目标的各类数据，提供数据关系图、时序图等多种数据展示形式。
              </div>
            }>
              <Item
                style={{
                  top: '1.37rem',
                  right: '.4rem',
                }}
                defaultImage={require('../../assets/pageThree/数据展示-默认.png')}
                hoverImage={require('../../assets/pageThree/数据展示-悬停.png')}
              />
            </Popover>

            <Popover position='top' content={
              <div style={{fontSize: '0.14rem'}}>
                提交的原始数据和解析后的数据都将以任务为单位进行归档。
              </div>
            }>
              <Item
                style={{
                  left: '0.62rem',
                  top: '2.72rem',
                }}
                defaultImage={require('../../assets/pageThree/数据归档-默认.png')}
                hoverImage={require('../../assets/pageThree/数据归档-悬停.png')}
              />
            </Popover>
          </div>
        </div>


      </div>
    </div>
  );
}
