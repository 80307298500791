import React, {useMemo, useState} from 'react';
import {useNavigate} from "react-router-dom";
import logo1 from '../../assets/feedback/logo1.png';
import logo2 from '../../assets/feedback/logo2.png';
import fileImg from '../../assets/feedback/file.png';
import './index.less'
import message from "../../components/message";
import WeChatGroup from "../../components/WeChatGroup";

const ImgIcon = (props: any) => {
  return <svg {...props}
    width="15px" height="15px">
    <path fill-rule="evenodd"  fill="currentColor"
          d="M13.188,3.223 L13.188,13.794 C13.188,14.063 12.969,14.282 12.700,14.282 L2.942,14.282 C2.673,14.282 2.454,14.063 2.454,13.794 L2.454,3.223 L0.827,3.223 L0.827,2.247 L2.942,2.247 L4.930,2.247 L5.861,0.549 C5.946,0.392 6.111,0.295 6.289,0.295 L9.375,0.295 C9.555,0.295 9.720,0.394 9.805,0.552 L10.715,2.247 L12.700,2.247 L14.814,2.247 L14.814,3.223 L13.188,3.223 ZM9.083,1.271 L6.578,1.271 L6.043,2.247 L9.607,2.247 L9.083,1.271 ZM12.212,3.223 L10.423,3.223 L5.219,3.223 L3.430,3.223 L3.430,13.306 L12.212,13.306 L12.212,3.223 ZM7.008,10.704 L6.032,10.704 L6.032,5.499 L7.008,5.499 L7.008,10.704 ZM9.610,10.704 L8.634,10.704 L8.634,5.499 L9.610,5.499 L9.610,10.704 Z"/>
  </svg>
}

const Index = () => {
  // 类型 1 : bug 2: 建议
  const [type, setFeedbackType] = useState(2);
  const [content, setFeedbackContent] = useState('');
  const [nickname, setName] = useState('');
  const [contact, setContact] = useState('');
  const [attachment, setAttachment] = useState<any>(null);
  // 显示反馈成功
  const [success, setSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleFormSubmit = () => {
    if (submitting) return;
    if (!content) {
      message.info('请输入反馈内容');
      return;
    }
    if (!nickname) {
      message.info('请输入称呼');
      return;
    }
    const formData = new FormData();
    formData.append('type', type.toString());
    formData.append('content', content);
    formData.append('nickname', nickname);
    formData.append('contact', contact);
    if (attachment) {
      formData.append('attachment', attachment);
    }
    setSubmitting(true);
    fetch('/official/feedback', {
      method: 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(res => {
        if (res?.code === 200) {
          setSuccess(true);
        } else {
          message.info(res?.msg || '保存失败')
        }
      }).catch(() => {
      message.info('服务器错误')
    }).finally(() => {
      setSubmitting(false);
    })
  };

  const handleFileChange = (event: any) => {
    const selectedFile = event.target.files[0];
    console.log('selectedFile:', selectedFile)
    if (selectedFile) {
      const reg = /\.(pdf|log|doc|docx|png|jpg|jpeg|zip|rar)$/

      if (reg.test(selectedFile.name)) {
        const maxSize = 20 * 1024 * 1024; // 20MB，转换为字节
        if (selectedFile.size <= maxSize) {
          setAttachment(selectedFile);
        } else {
          message.info('文件大小不超过20M', {
            hideAfter: 4
          })
        }
      } else {
        message.info('文档格式支持：pdf\\log\\doc\\docx\\png\\jpg\\jpeg\\zip\\rar', {
          hideAfter: 4
        })
      }
    }
    event.target.value = null; // 清空文件输入框的值
  }

  const deleteFile = () => {
    setAttachment(null);
  }

    const disabled = useMemo(() => {
      if (!content || !nickname) return true;
      return false;
    }, [content, nickname])

  if (success) {
    return <div className="feedback">
      <div className="feedback-success">
        <img src={logo2} alt="反馈成功"/>
        <div className='feedback-msg'>您的反馈已成功提交，感谢您的宝贵意见！</div>
        <div className='btn_body'>
          <div className="back-home-btn" onClick={() => {
            navigate('/')
          }
          }>返回首页
          </div>
          <WeChatGroup>
            <div className="back-home-btn">微信公众号</div>
          </WeChatGroup>
        </div>
      </div>
    </div>
  }

  return (
    <div className='feedback'>
      <div className="feedback-content">
        <div className="header">
          <img src={logo1} alt="用户反馈"/>
        </div>
        <form>
          <div className='form-item'>
            <label>
              反馈类型
            </label>
            <div className='radio-container'>
              <div className={`radio-item ${type === 2 ? 'radio-item-active' : ''}`}
                   onClick={() => setFeedbackType(2)}
              />
              建议
              <div className={`radio-item ${type === 1 ? 'radio-item-active' : ''}`}
                   style={{marginLeft: '.18rem'}}
                   onClick={() => setFeedbackType(1)}
              />
              Bug
            </div>
          </div>
          <div className='form-item' style={{marginBottom: '.02rem'}}>
            <label>
              <span>*</span>反馈内容
            </label>
            <div style={{paddingBottom: '.17rem'}}>
              <textarea
                value={content}
                placeholder='请输入反馈内容'
                onChange={(e) => setFeedbackContent(e.target.value)}
                maxLength={1000}
              />
              <span className='count-words' style={{bottom: 0, right: 0}}>{content.length}/1000</span>
            </div>
          </div>
          <div className='upload-container'>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div className='uploadButton' onClick={() => {
                document.getElementById('uploadFile')?.click()
              }}>
                上传附件
              </div>
              {attachment?.name && <div className="file-list">
                  <div style={{flex: 1, display: 'flex', alignItems: 'center'}}>
                      <img className='file-img' src={fileImg} alt="附件"/>
                      <div className='file-name'>{attachment.name}</div>
                  </div>
                  <div className='delete-btn' onClick={deleteFile}>
                      <ImgIcon />
                  </div>
              </div>}
            </div>
            <div className='extensions'>支持扩展名：.pdf\.log\.doc\.docx\.png\.jpg\.jpeg\.zip\.rar</div>
            <input type="file"
                   id='uploadFile'
                   accept=".pdf, .log, .doc, .docx, .png, .jpg, .jpeg, .zip, .rar"
                   onChange={handleFileChange}/>
          </div>
          <div className='form-item'>
            <label>
              <span>*</span>称呼
            </label>
            <div>
              <input
                type="text"
                value={nickname}
                maxLength={15}
                placeholder='请留下您的称呼，如果您的反馈被接纳，我们将在官网向您致谢'
                onChange={(e) => setName(e.target.value)}
              />
              <span className='count-words'>{nickname.length}/15</span>
            </div>
          </div>
          <div className='form-item'>
            <label>
              联系方式
            </label>
            <div>
              <input
                type="text"
                value={contact}
                placeholder='请输入您的联系方式'
                maxLength={60}
                onChange={(e) => setContact(e.target.value)}
              />
              <span className='count-words'>{contact.length}/60</span>
            </div>
          </div>
          <div className={`submit-button ${disabled || submitting ? 'submit-button-disabled' : ''}`}
               onClick={handleFormSubmit}>
            <span>提交</span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Index;
