/**
 * 用户协议
 */
import './agreement.less'
import img from '../../assets/agreement_logo.png'
import Footer from "../../components/Footer";

const Agreement = () => {
  return <div className='agreement-page'>
    <header>
      <a href="/projects/tanggo_website/public">
        <img src={img} alt="TangGo"/>
      </a>
    </header>
    <div className='agreement-content'>
      <h2>软件许可协议</h2>
      <p style={{fontWeight: 'bold'}}>请您仔细阅读。本协议是您与我们（成都无糖信息技术有限公司，以下简称“无糖信息”）签定的软件许可协议。</p>
      <p>在使用我们（无糖信息）的软件（以下称许可软件）之前，请您仔细阅读本协议，特别是免除或者限制责任的条款、法律适用和争议解决条款。</p>
      <p>无糖信息TangGo测试平台软件，以下称为“本软件”。</p>
      <p style={{fontWeight: 'bold'}}>重要提示：本《软件许可协议》（以下称为“本协议”）是您（自然人、法人或其他组织）与
        我们（成都无糖信息技术有限公司）之间有关复制、下载、安装、购买、使用无糖信息软件以及任何相关材料（统一称作“软件”）及为该软件提供的任何更新（包括但不限于：错误修正程序、补丁程序、更新、升级、增强、新版软件和后续软件，这些内容统称为“更新”）和支持服务的法律协议。</p>
      <p>一旦您复制、下载、安装或者以其他方式使用本软件，即表明您完全同意并接受本协议各项条款，同时包括接受我们对协议各项条款随时所做的任何修改。如果您不同意本协议中的条款，请勿复制、下载、查看、安装或者以其他方式使用本软件。</p>
      <h3>1.软件许可：</h3>
      <p>在您完全接受并遵守本协议的基础上，本协议授予您使用本软件的某些权利和非独占性许可，允许您依据本协议各项条款许可的用途使用本软件，我们保留所有其他权利。</p>
      <p>本协议中，将软件使用用途分为“个人非商业用途”和“商业用途”。</p>
      <p>获得软件授权之后，您可以将本软件应用于“商业用途”，同时依据您所获得的授权服务类型中确定的技术支持期限、技术支持方式和技术支持内容获得指定范围内的技术支持服务。</p>
      <h3>2.试用版软件：</h3>
      <p>本软件可能提供试用版本，试用版软件仅用于在有限的期限内检查、演示和评估软件，其性能和兼容性均未能达到最终全面发布版本的级别，将来也存在对软件进行重大更改的可能，其仅供试用软件之用，请勿用于正式生产环境，也请勿泄漏于其他用户，如软件试用期间导致的软件程序泄漏，我司保留追究试用软件泄漏的权力。</p>
      <h3>3.约束和限制：</h3>
      <p>（1）未获得本软件使用授权之前，您不得将本软件用于商业用途。</p>
      <p>（2）无论如何，即无论用途如何、是否经过修改或美化、修改程度如何，只要使用本软件的整体或任何部分，未经我们官方书面授权许可，软件的版权标识禁止去除。</p>
      <p>（3）我们提供的或您获得的有关本软件的任何信息只能由您为本协议许可的目的而使用，您不得透露给任何第三方；您不得创建任何与本软件风格基本相似的软件。</p>
      <p>（4）对于从我们取得的任何信息、软件、产品或服务，您不得对其进行修改、改编、反编译、逆向工程、反汇编及绕过软件中的任何技术限制、或基于以上内容创建衍生作品、或以其他方式试图从本软件取得源代码、或重新包装本软件、或从软件文档中摘取其实质部分作其他应用。</p>
      <p>（5）您不得对本软件以及与之关联的商业授权进行发布、出租、销售、分销、抵押、转让、许可或发放子许可证。</p>
      <h3>4.用户使用规则</h3>
      <p>请注意，使用本软件可能会涉及到法律和合规问题。您在使用本软件或服务时，不得将本软件产品或服务用于任何非法用途或本协议条款、条件和声明禁止的用途。您必须确保其使用符合所有相关的法律法规，包括但不限于数据隐私法规和网络安全法规等。如果您不确定您的使用是否合法，请在使用之前咨询法律专业人士。</p>
      <p>4.1用户在使用本软件时必须符合中国有关法规以及其他有关国家和地区的法律规定以及国际法的有关规定。</p>
      <p>4.2用户不得利用本软件从事以下危害计算机网络安全的活动，如果我们有理由认为用户的行为违反或可能违反下述规定的，我们可独立进行判断并处理，且有权在不事先通知的情况下终止向用户提供服务，并追究相关法律责任：</p>
      <p>（1）未经允许，进入计算机信息网络或者使用计算机信息网络资源的；</p>
      <p>（2）未经允许，对计算机信息网络功能进行删除、修改或者增加的；</p>
      <p>（3）未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；</p>
      <p>（4）故意制作、传播计算机病毒等破坏性程序的；</p>
      <p>（5）使用未经许可的数据或进入未经许可的服务器/账户；</p>
      <p>（6）未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；</p>
      <p>（7）未经许可，企图探查、扫描、测试他人计算机软件系统或网络的弱点或其它实施破坏网络安全的行为；</p>
      <p>（8）企图干涉、破坏他人计算机软件系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；</p>
      <p>（9）其他危害计算机信息网络安全的行为。</p>
      <p>4.3用户须对自己在使用本软件服务过程中的行为承担法律责任。</p>
      <h3>5.免责说明：</h3>
      <p>（1）使用本软件可能存在风险，您必须自行承担其使用本软件的所有风险。</p>
      <p>（2）使用本软件是基于您自己的判断和风险承受能力，并且您必须自行对其使用后果负责。</p>
      <p>（3）在任何情况下，无论是因使用本软件或由于本软件引起的其他问题，我们不对您因使用本软件而导致的任何直接或间接损失负责，包括但不限于数据丢失，利润损失，业务中断等。</p>
      <p>（4）任何对本软件的不当使用，包括但不限于非法入侵、攻击或破坏他人的系统或数据等行为，均由您自行承担法律责任。本软件不提供任何形式的保证。</p>
      <p>（5）本软件不得用于非法的、未授权的或违反道德规范的活动。包括但不限于攻击、侵入、破解、欺骗、窃取信息或其他非法或恶意活动。我们不对您因使用本软件而导致的任何法律责任或其他责任承担任何责任，包括但不限于因违反法律法规而导致的责任。使用本软件的风险和责任由您自行承担。</p>
      <h3>6.权利和所有权的保留：</h3>
      <p>我们保留所有未在本协议中明确授予您的所有权利。我们保留随时更新本协议的权利，并只需公示于成都无糖信息技术有限公司官方网站，而无需征得您的事先同意且无需另行通知，更新后的内容应于公示即时生效。您可以随时访问无糖信息官方网站查阅最新版许可条款，在更新生效后您继续使用本软件则被视作您已接受了新的条款。</p>
      <h3>7.知识产权</h3>
      <p>（1）本软件知识产权归我们独立所有。软件的结构、组织和代码以及与软件相关的所有信息内容为我们的保密信息。未经我们明确授权，您不得使用、复制、分发或修改本软件的任何组件。</p>
      <p>（2）我们拥有许可软件的著作权、商业秘密以及其他相关的知识产权，包括与许可软件有关的各种文档资料。</p>
      <p>（3）许可软件的相关标识属于我们及我们的关联公司的知识产权，并受到相关法律法规的保护。未经我们明确授权，您不得复制、模仿、使用或发布上述标识，也不得修改或删除应用产品中体现无糖信息及其关联公司的任何标识或身份信息。</p>
      <p>（4）未经我们及我们的关联公司事先书面同意，您不得为任何营利性或非营利性的目的自行实施、利用、转让或许可任何第三方实施、利用、转让上述知识产权。</p>
      <h3>8.协议终止</h3>
      <p>（1）您应理解按授权范围使用许可软件、尊重软件及软件包含内容的知识产权、按规范使用软件、按本协议约定履行义务是您获取我们授权使用软件的前提，如您严重违反本协议，我们将终止使用许可。</p>
      <p>（2）您一旦开始复制、下载、安装或者使用本软件，即被视为完全理解并接受本协议的各项条款，在享有上述条款授予的许可权力同时，也受到相关的约束和限制，本协议许可范围以外的行为，将直接违反本协议并构成侵权。</p>
      <p>（3）一旦您违反本协议的条款，我们随时可能终止本协议、收回许可和授权，并要求您承担相应法律和经济责任。</p>
      <h3>9.其他</h3>
      <p>本协议的所有标题仅仅是为了醒目及阅读方便，本身并没有实际涵义，不能作为解释本协议涵义的依据。如果本协议中的任何条款被认定为无效或不可执行，该条款的无效性或不可执行性将不影响本协议的其他条款。</p>
      <p>本协议受中华人民共和国法律管辖。若您在使用本软件期间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交【成都仲裁委员会】仲裁解决。</p>
      <p>成都无糖信息技术有限公司</p>
    </div>
    <Footer global={false} />
  </div>
}
export default Agreement