import './VersionsModal.less';
import React, {useEffect, useState} from 'react';
import communityImg from '../../assets/pageSeven/VersionsModal/社区版-BG-一倍图.png';
import enterpriseImg from '../../assets/pageSeven/VersionsModal/企业版-BG-1倍图.png';
import specialtyImg from '../../assets/pageSeven/VersionsModal/专业版-BG-一倍图.png';
import {Image} from '../PageFive';
import ApplyForModal from "./ApplyForModal";
import {createPortal} from "react-dom";

const CloseIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
    <path id="形状_512_拷贝_2" data-name="形状 512 拷贝 2" fill='currentColor'
          d="M1518.56,220.761l-4.26,4.262-4.27-4.262-0.73.737,4.26,4.263-4.26,4.262,0.73,0.738,4.27-4.262,4.26,4.262,0.74-.738-4.26-4.262,4.26-4.263Z"
          transform="translate(-1509.31 -220.75)"/>
  </svg>;
};
const Modal = ({open, onClose, onCommunityClick, onSpecialtyClick, onEnterpriseImgClick}: any) => {

  const [openApplyFor, setOpenApplyFor] = useState(false);

  if (!open) {
    return null;
  }

  return (
    createPortal(<>
      <div className="modal-overlay VersionsModal" onClick={onClose}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="modal-header">
            <div className="closeBtn" onClick={onClose}>
              <CloseIcon/>
            </div>
          </div>
          <div className='ul'>
            <div>
              <Image src={communityImg}/>
              <Image className='tp' src={require('../../assets/pageSeven/VersionsModal/社区版1.png')}/>
              <div className='jiesao'>
                社区版内置了一些常用的资产信息收集、功能测试和安全测试工具，适合软件测试人员软件测试以及信息安全相关学生、研究人员及爱好者个人学习和研究使用。
              </div>
              <div
                className='btn'
                onClick={() => {
                  onCommunityClick?.();
                }}
              >
                下载社区版
              </div>
            </div>
            <div>
              <Image src={specialtyImg}/>
              <Image className='tp' src={require('../../assets/pageSeven/VersionsModal/专业版2.png')}/>
              <div className='jiesao'>
                专业版包含更丰富的各类测试工具，功能更加完善，适合软件测试人员、渗透测试从业人员、安全研究人员等专业人员在安全测试、安全演练及安全研究等场景使用。
              </div>
              <div
                className='btn'
                onClick={() => {
                  window.version = 0;
                  setOpenApplyFor(true);
                  onSpecialtyClick?.();
                }}
              >
                申请试用
              </div>
            </div>
            <div>
              <Image src={enterpriseImg}/>
              <Image className='tp' src={require('../../assets/pageSeven/VersionsModal/企业版3.png')}/>
              <div className='jiesao'>
                企业版在专业版的基础上提供了高效和便利的团队协同测试的解决方案，搭载了协同测试平台，对任务进行统一管理和调度，团队成员测试数据实时共享，适合企业级的测试团队协同使用。
              </div>
              <div
                className='btn'
                onClick={() => {
                  setOpenApplyFor(true);
                  onEnterpriseImgClick?.();
                }}
              >
                申请试用
              </div>
            </div>
          </div>
        </div>
      </div>
      <ApplyForModal
        open={openApplyFor}
        onClose={() => {
          setOpenApplyFor(false);
          //@ts-ignore
          window.version = 2;
        }}
      />
    </>, document.body)
  );
};

export default Modal;
