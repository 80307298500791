import {ReactElement, JSXElementConstructor} from "react";
import {Popover} from "../Popover/Popover";
import './index.less';
import {Image} from "../PageFive";

export default function WeChatGroup(props: { children: ReactElement<any, string | JSXElementConstructor<any>>; }) {
  return (
    <Popover
      position='top'
      top={-10}
      content={
        <div className='WeChatGroup'>
          <div className='title'>公众号</div>
          <div className='hint'>扫码关注公众号</div>
          <div className='body'>
            <div className='body_item'>
              <Image className='img_body' src={require('../../assets/footer/tangGo公众号.jpg')}/>
              TangGo测试平台
            </div>
      {/*      <div className='body_item'>
              <Image className='img_body' src={require('../../assets/footer/1.png')}/>
              无糖信息
            </div>
            <div className='body_item'>
              <Image className='img_body' src={require('../../assets/footer/3.webp')}/>
              无糖反网络犯罪研究中心
            </div>*/}
          </div>
        </div>
      }
    >
      {
        props.children
      }
    </Popover>
  );
}
