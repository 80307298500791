import React, {useEffect, useState} from "react";
// import videoSrc from '../../assets/demo.mp4'
import './index.less'
import lottie from 'lottie-web';
import data_footer from './json/data_footer.json';
import data_light from './json/data_light.json';
import data1 from './json/data1.json';
import data1_line from './json/data1_line.json';
import data2 from './json/data2.json';
import data2_line from './json/data2_line.json';
import data3 from './json/data3.json';
import data3_line from './json/data3_line.json';
import data4 from './json/data4.json';
import data4_line from './json/data4_line.json';
import data5 from './json/data5.json';
import data5_line from './json/data5_line.json';
import {Carousel} from "react-responsive-carousel";
import list from './data'

const config: any = {
  renderer: 'svg',
  loop: false,
  autoplay: true,
}
// 第一个块离左边距离
const leftSize = '.425rem';
const blockWidth = '1.46rem';
const blockGap = '.04rem';
const animationArr = [data1, data2, data3, data4, data5];
const animationLineArr = [data1_line, data2_line, data3_line, data4_line, data5_line];

const renderIndicator = (clickHandler: any, isSelected: boolean) => {
  return <div className={`indicator-custom ${isSelected ? 'indicator-active' : ''}`}
              onClick={clickHandler}/>;
};

const PageTwo = () => {

  const [active, setActive] = useState<number>(0);

  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleCarouselChange = (index: number) => {
    // 在轮播变更时更新当前选定的索引
    setSelectedIndex(index);
  };

  const resetToFirstSlide = () => {
    // 重置到第一个激活状态的轮播项
    setSelectedIndex(0);
  };

  useEffect(() => {
    const container = document.getElementById('lottie-' + active);
    const line = document.getElementById('lottieLine-' + active);

    let anim: any = null;
    let animLine: any = null;

    if (container && line) {
      if (anim !== null) {
        anim.destroy();
      }
      if (animLine !== null) {
        animLine.destroy();
      }

      anim = lottie.loadAnimation({
        container: container,
        ...config,
        animationData: animationArr[active] // 导入的动画 JSON 数据
      });
      animLine = lottie.loadAnimation({
        container: line,
        ...config,
        animationData: animationLineArr[active]
      });
    }

    const light = document.getElementById('lottieLight');
    const footer = document.getElementById('lottieFooter');
    let animLight: any = null;
    let animFooter: any = null;
    if (light && footer) {

      if (animLight !== null) {
        animLight.destroy();
      }

      if (animFooter !== null) {
        animFooter.destroy();
      }
      console.log('animFooter')
      animFooter = lottie.loadAnimation({
        container: footer,
        ...config,
        animationData: data_footer
      });
      animLight = lottie.loadAnimation({
        container: light,
        ...config,
        animationData: data_light
      });
    }

    return () => {
      anim?.destroy();
      animLine?.destroy();
      animLight?.destroy();
      animFooter?.destroy();
    } // 在组件卸载时销毁动画
  }, [active])

  // console.log(list, 'list')
  return <div className='page-tools'>
    <div className="page-tools-content">
      {list.map((item, i) => {
        // 两个线条差距147px
        const line_l = `calc((${leftSize} + ${blockWidth} / 2) + 1.49rem * ${i})`;
        const box_left = `calc(${blockWidth} * ${i} + ${leftSize} + ${blockGap} * ${i})`
        return <div key={'lottie#' + i}>
          <div id={'lottie-' + i} style={{left: box_left}}/>
          <div id={'lottieLine-' + i}
               style={{
                 width: `${item.line_w / 2 / 100}rem`,
                 height: `${item.line_h / 2 / 100}rem`,
                 left: i === 0 || i === 2 ? undefined : line_l,
               }}/>
        </div>
      })}
      <ul className='tool-list'>
        {list.map((item, i) => {
          return <li className={`tool-item-${i + 1} ${active === i ? 'tool-item-active' : ''}`}
                     key={'tools#' + i}
                     id={'tool-item-' + (i + 1)}
                     onClick={() => {
                       setActive(i)
                       resetToFirstSlide()
                     }}
          />
        })}
        <div id={'lottieFooter'} style={{left: `calc(${45 / 100}rem + 1rem * ${active} + .51rem * ${active})`}}/>
        <div id={'lottieLight'}/>
      </ul>
      <div className="page-tools-description">
        <div className="title">[ 工具集介绍 ]</div>
        <div
          className="content">{list[active].description}
        </div>
      </div>
      <div className="video-container">
        {/*   <video controls autoPlay src={videoSrc} style={{width: '100%', height: '100%'}}>
          Your browser does not support the video tag.
        </video>*/}
        <Carousel autoPlay={true}
                  showThumbs={false}
                  showArrows={false}
                  renderIndicator={renderIndicator}
                  showStatus={false}
                  interval={3000}
                  infiniteLoop
                  onChange={handleCarouselChange}
                  selectedItem={selectedIndex}
        >
          {list[active].images.map((item, index) => <div key={`${active}#${index}`}>
            <img src={item} alt={`图${active}_${index}`}/>
          </div>)}
        </Carousel>
      </div>
    </div>
  </div>
}
export default PageTwo