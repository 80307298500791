import './index.css'
import cogoToast, {CTOptions} from "cogo-toast";
import {ReactNode} from "react";
import InfoImg from "../../assets/pageOne/info.png";
const message = {
  info: (text: string | ReactNode, options?: CTOptions) => cogoToast.info(text, {
    hideAfter: 2,
    bar: {size: '0'},
    renderIcon: () => <img src={InfoImg} alt="提示" style={{width: 14, height: 14, marginTop: 2.5}} />,
    ...options,
  })
}
export default message