import {CSSProperties, memo, useEffect, useState} from 'react';
import './index.less';
import list from './data';

interface ImageProps {
  className?: string | undefined;
  src?: string | undefined;
  style?: CSSProperties | undefined;
  onClick?: () => void;
}

export const Image = memo(function Image({src, className, style, onClick}: ImageProps) {
  return (
    <div style={{display: 'flex', ...style}} className={className} onClick={onClick}>
      <img className='img' src={src} alt=""/>
    </div>
  );
});
let setTimeoutId: string | number | NodeJS.Timeout | null | undefined = null;
export default function PageFive() {
  const [introduce, setIntroduce] = useState(list[0]);
  const [slide, setSlide] = useState(0);
  useEffect(() => {
    slideMethod();
    return () => {
      setTimeoutId && clearTimeout(setTimeoutId);
    };
  }, [slide]);

  function slideMethod() {
    /*setTimeoutId = setTimeout(() => {
      setSlide(slide < introduce?.children.length - 1 ? slide + 1 : 0);
      slideMethod();
    }, 5000);*/
  }

  return (
    <div className='pageFive'>
      <Image className='img' src={require('../../assets/pageFive/标题.png')}/>
      <div className='body'>
        <div className='menu'>
          <Image className='img' src={require('../../assets/pageFive/菜单容器.png')}/>
          <div className='menu_inner'>
            {list.map((item, index) => {
              return (
                <div
                  style={{
                    "--default": `url(${item.titleImage.default})`,
                    "--hover": `url(${item.titleImage.hover})`,
                    "--active": `url(${item.titleImage.active})`,
                  } as any}
                  key={index}
                  className={['menu_inner_item', introduce?.title === item.title ? 'selected' : ''].join(' ')}
                  onClick={() => {
                    setIntroduce(item);
                    setTimeoutId && clearTimeout(setTimeoutId);
                    setSlide(() => 0);
                  }}
                />
              );
            })}
          </div>
        </div>
        <div className='content'>
          <div className='content_inner'>
            <div
              className='slide'
              style={{
                transform: `translateX(-${slide}00%)`,
              }}
              onMouseOver={() => {
                setTimeoutId && clearTimeout(setTimeoutId);
              }}
              onMouseOut={() => {
                slideMethod();
              }}
            >
              {
                introduce?.children?.map((item, index) => {
                  return (
                    <div className='top' key={index}>
                      <div className='left'>
                        {
                          item.statics.map((staticsItem, k) => {
                            return <Image src={staticsItem} key={k} style={{flex: 1}}/>;
                          })
                        }
                      </div>
                      <div className='right'>
                        <div className='header'>
                          <div className='header_title' style={introduce.titleIconStyle}>
                            <Image src={introduce.titleIcon}/>
                          </div>
                          <div>{item.intro}</div>
                        </div>
                        <div>
                          <div className='desc'>
                            {
                              item.desc
                            }
                          </div>
                          <ul className='support'>
                            {
                              item.list.map((listItem, i) => {
                                return (
                                  <li key={i} className='support_item'>
                                    {listItem}
                                  </li>
                                );
                              })
                            }
                          </ul>
                        </div>
                      </div>
                    </div>
                  );
                })
              }
            </div>

          </div>
          <ul className='footer'>
            {
              introduce?.children?.map((item, index) => {
                return (
                  <li
                    className={['footer_item', index === slide ? 'selected' : ''].join(' ')}
                    key={index}
                    onClick={() => {
                      setSlide(index);
                    }}
                  >
                    {index + 1}
                  </li>
                );
              })
            }
          </ul>
        </div>
      </div>
    </div>
  );
}
