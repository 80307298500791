import PageOne from "../../components/PageOne";
import PageTwo from "../../components/PageTwo";
import PageThree from "../../components/PageThree";
import PageFour from "../../components/PageFour";
import PageFive from "../../components/PageFive";
import PageSix from "../../components/PageSix";
import PageSeven from "../../components/PageSeven";
import React, {useEffect, useState} from "react";
import DownloadModal from "../../components/PageOne/DownloadModal";
import VersionsModal from "../../components/PageSeven/VersionsModal";
import VideoModal from "../../components/VideoModal";

const AppPage = () => {
  const [open, setOpen] = useState(false);
  // 打开播放视频弹窗
  const [openVideo, setOpenVideo] = useState(false);

  const [videoSrc, setVideoSrc] = useState('');
  const [openVersions, setOpenVersions] = useState(false);
  useEffect(() => {
    console.log(22222222);
  }, []);
  return <>
    <PageOne
      onDownloadClick={() => {
        setOpen(true);
      }}
      onVideoClick={(videoUrl) => {
        setOpenVideo(true);
        setVideoSrc(videoUrl);
      }}
    />
    <PageTwo/>
    <PageThree/>
    <PageFour/>
    <PageFive/>
    <PageSix/>
    <PageSeven
      onDownloadClick={() => {
        setOpen(true);
      }}
      onMoreClick={() => {
        setOpenVersions(true);
      }}
    />
    <DownloadModal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    />
    <VersionsModal
      open={openVersions}
      onClose={() => {
        setOpenVersions(false);
      }}
      onCommunityClick={() => {
        setOpen(true);
      }}
    />
    <VideoModal
      isOpen={openVideo}
      src={videoSrc}
      // src={'https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4'}
      onClose={() => {
        setOpenVideo(false);
      }}
    />
  </>;
};
export default AppPage;
