import './index.less';

const Index = () => {
  return (
    <div className='pageSix'>
      <div className='title'>
        <img src={require(`../../assets/pageSix/title.png`)} alt="" className='title_inner'/>
      </div>
      <ul className='list'>
        {
          Array.from(Array(8)).map((_, index) => {
            return (
              <li key={index}>
                <img src={require(`../../assets/pageSix/${index + 1}@2x.png`)}/>
              </li>
            );
          })
        }
      </ul>
    </div>
  );
};
export default Index;
