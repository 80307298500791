import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from 'react-responsive-carousel';
import './index.less';
import carousel1 from '../../assets/pageOne/1.png';
import carousel2 from '../../assets/pageOne/2.png';
import carousel3 from '../../assets/pageOne/3.png';
import carousel4 from '../../assets/pageOne/4.png';
import videoSrc from '../../assets/demo.mp4';
import {Popover} from "../Popover/Popover";
import WeChatGroup from "../WeChatGroup";

/*const list = [
  '工具类型丰富，能满足各类渗透测试需求',
  '支持团队协同作战，高效管理作战任务，提高团队效率',
  '支持远程工具库，用户可通过远程工具库进行快速更新',
  '跨平台，支持多种操作系统，部署简单，无环境依赖',
  '基于多年渗透测试实战经验开发，解决渗透测试中的难点痛点'
]*/
interface IndexProps {
  onDownloadClick?: () => void;
  onVideoClick?: (videoUrl: string) => void;
}

const Index = (props: IndexProps) => {
  const renderIndicator = (clickHandler: any, isSelected: boolean, index: number) => {
    return <div className={`indicator-custom ${isSelected ? 'indicator-active' : ''}`}
                onClick={clickHandler}/>;
  };

  return <div className='pageOne'>
    <div className="page-one-content">
      <div className="left">
        {/*    <div className="animate__customMove title">
        <p className='info'>支持协同图案通作战，高效管理图案段效率——N98</p>
        <p
          className='describe'>TangGo渗透测试平台是无糖信息技术有限公司集多年网络攻防实战经验设计和开发的渗透测试平台。主要用于计算机系统模拟黑客的渗透测试检测和安全评估，帮助网络安全从业人员扫描、发现和测试、评估计算机系统的各类安全漏洞。</p>
      </div>*/}
        {/*     <div className="animate__customMove list">
        <ul>
          {list.map((item, i) => (<li key={'item#' + i}>
            <img src={require(`../../assets/pageOne/icon${i + 1}.png`)} alt={item}/>
            <div className='text'>{item}</div>
          </li>))}
        </ul>
      </div>
      <div className="animate__customMove tip">
        <img src={banner} alt=""/>
      </div>*/}
        <div className="animate__customMove downloadBox">
          <div className="downloadBtnClickArea"
               onClick={() => {
                 props?.onDownloadClick?.();
               }}/>
          <div className="downloadBtn"/>
          <div className="videoBtnClickArea"
               onClick={() => {
                 props?.onVideoClick?.(videoSrc);
               }}/>
          <div className="videoBtn"/>
          <WeChatGroup>
            <div className="weChatBtnClickArea"/>
          </WeChatGroup>
          <div className="weChatBtn"/>
        </div>
        {/*      <div className="animate__customMove platformAll">
        <div className="content">
          <div>[ 支持平台 ]</div>
          <div className="lineFull"/>
          <img src={winImg} alt="Windows"/>
          <div className="line"/>
          <img src={iosImg} alt="macOS"/>
          <div className="line"/>
          <img src={linuxImg} alt="Linux"/>
        </div>
        <img src={tip} alt="支持平台"/>
      </div>*/}
      </div>
      <div className="animate__customMove right">
        <div className='carousel-bg'>
          <Carousel autoPlay={true}
                    showThumbs={false}
                    showArrows={false}
                    renderIndicator={renderIndicator}
                    showStatus={false}
                    interval={3000}
                    infiniteLoop
          >
            <div>
              <img src={carousel1} alt='图1'/>
            </div>
            <div>
              <img src={carousel2} alt='图2'/>
            </div>
            <div>
              <img src={carousel3} alt='图3'/>
            </div>
            <div>
              <img src={carousel4} alt='图4'/>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  </div>;
};
export default Index;
