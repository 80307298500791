export default [
  {
    "title": "漏洞验证工具",
    "titleImage": {
      "default": require('../../assets/pageFive/漏洞验证工具-默认.png'),
      "hover": require('../../assets/pageFive/漏洞验证工具-悬停.png'),
      "active": require('../../assets/pageFive/漏洞验证工具-选中.png'),
    },
    titleIcon: require('../../assets/pageFive/赛事简介 拷贝@2x.png'),
    titleIconStyle: {
      width: '1.3rem',
    },
    "children": [
      {
        "intro": "工具简介",
        "desc": "漏洞验证工具是一款可针对单个或多个目标进行指定范围的漏洞验证的工具。独创的无代码可视化开发框架，可快速开发、测试和验证PoC/EXP，甚至可以一键生成独立的跨平台的测试工具。内置全面的测试流程，测试过程透明详尽且易于跟踪调试，还能自动生成测试报告。",
        "list": [
          "集成无代码可视化快速开发框架，通过简单界面配置即可实现复杂的测试流程",
          "内置丰富的测试流程和丰富的数据处理规则，轻松实现各流程的顺畅衔接和数据流转",
          "调试、测试环节透明可跟踪，轻松掌握各测试环节的工作情况、数据和变量变化",
          "支持批量测试目标的漏洞验证，漏洞测试过程透明且详细，可以实时观测和复测，报告详尽",
          "支持PoC导出以及将PoC一键生成跨平台的独立的工具",
        ],
        "statics": [
          require('../../assets/pageFive/static/漏洞验证工具/P1_ 漏洞验证工具 _ 工具简介.png'),
        ],
      },
      {
        "intro": "无代码可视化开发框架",
        "desc": "搭载基于流程可视化的无代码快速开发框架，集开发、调试、测试和批量验证、一键跨平台工具生成和报告导出，大大降低了技术门槛，缩短了开发周期，提高了开发效率，让无编程开发基础及非专业测试人员也能轻松使用。",
        "list": [
          "集成无代码可视化快速开发框架，通过简单界面配置即可实现复杂的测试流程",
          "内置丰富的测试流程和丰富的数据处理规则，轻松实现各流程的顺畅衔接和数据流转",
          "调试、测试环节透明可跟踪，轻松掌握各测试环节的工作情况、数据和变量变化",
          "支持PoC导出以及将PoC一键生成跨平台的独立的工具",
        ],
        "statics": [
          require('../../assets/pageFive/static/漏洞验证工具/P2_漏洞验证工具  无代码可视化开发框架.png'),
        ],
      },
      {
        "intro": "丰富的测试流程",
        "desc": "丰富的测试流程，涵盖数据发送、数据处理、浏览器自动化测试，满足您的各类测试需求。",
        "list": [
          "支持HTTP/UDP/TCP多种数据发送和接收",
          "支持基于无头浏览器的自动化测试",
          "支持通信劫持和断点劫持，能够自动寻找断点位置",
          "支持验证码识别和矫正",
          "内置多种数据处理和判断方法，支持引入第三方工具或脚本处理数据",
        ],
        "statics": [
          require('../../assets/pageFive/static/漏洞验证工具/P3-漏洞验证工具-丰富的测试流程.png'),
        ],
      },
      {
        "intro": "详尽的测试报告",
        "desc": "支持测试目标的批量验证及验证结果的筛选，可视化二次验证，以及详细的测试报告生成和导出。",
        "list": [
          "详细的测试报告，涵盖测试过程、测试数据、判断方法和关键变量显示",
          "支持将测试结果和报告一键推送到协同测试平台",
        ],
        "statics": [
          require('../../assets/pageFive/static/漏洞验证工具/P4-漏洞验证工具-详尽的测试报告.png'),
        ],
      },
    ],
  },
  {
    "title": "HTTP抓包测试工具",
    titleIcon: require('../../assets/pageFive/HTTP抓包测试工具@2x.png'),
    titleIconStyle: {
      width: '1.7rem',
    },
    "titleImage": {
      "default": require('../../assets/pageFive/HTTP抓包测试工具-默认.png'),
      "hover": require('../../assets/pageFive/HTTP抓包测试工具-悬停.png'),
      "active": require('../../assets/pageFive/HTTP抓包测试工具-选中.png'),
    },
    "children": [
      {
        "intro": "工具简介",
        "desc": "HTTP抓包测试工具是一款跨平台的功能强大的HTTP、WebSocket数据拦截、修改、重放的测试工具。",
        "list": [
          "支持HTTP、HTTPS、WebSocket数据拦截、修改、重放的测试；支持畸形数据包及二进制文件的处理；支持数据包的过滤、筛选、替换和修正。",
          "支持全局及局部环境变量。集成内置浏览器，集成Cookie-Editor插件。",
          "集成高性能代理池，支持代理轮换使用，支持各测试任务标签页的独立代理切换。",
          "集成HTTP测试数据收藏夹和Socket测试数据收藏夹以及Payload收藏夹。",
          "支持远程使用模式，支持不同用户共享HTTP抓包测试工具，支持只关闭界面不关闭服务模式。",
        ],
        "statics": [
          require('../../assets/pageFive/static/HTTP抓包测试工具/P1-HTTP抓包测试工具 -工具简介.png'),

        ],
      },
      {
        "intro": "支持多种测试场景",
        "desc": "HTTP抓包测试工具支持全局环境变量及局部环境变量，可解决每次发送数据包需要获取新Token或者新验证码的痛点，支持各类畸形数据包的发送和换行符的编辑。",
        "list": [
          "支持全局环境变量及局部环境变量，解决每次发送数据包需要获取新Token或者新验证码的痛点",
          "支持各类畸形数据包的发送、记录、比对、修改、测试",
          "支持HTTP、HTTPS、WebSocket数据拦截、修改、重放的测试",
          "支持二进制文件编辑修改和重放，支持换行符显示和编辑",
        ],
        "statics": [
          require('../../assets/pageFive/static/HTTP抓包测试工具/P2-HTTP抓包测试工具-支持多种测试场景.png'),

        ],
      },
      {
        "intro": "强大的数据包编辑功能",
        "desc": "HTTP抓包测试工具内置强大的数据包编辑功能，支持畸形数据包的解析及可视化修改，支持数据包格式转换、代码格式化、编码解码等多种功能。",
        "list": [
          "支持二进制文件编辑修改和重放，支持换行符显示和编辑",
          "支持各类畸形数据包的发送、记录、比对、修改、测试",
          "支持数据包格式转换、代码格式化、编码解码",
        ],
        "statics": [
          require('../../assets/pageFive/static/HTTP抓包测试工具/P3-HTTP抓包测试工具-强大的数据包编辑功能.png'),

        ],
      },
      {
        "intro": "数据包/Payload收藏夹",
        "desc": "HTTP抓包测试工具支持HTTP、Socket及Payload数据收藏，方便用户在测试时快速加载所需数据。",
        "list": [
          "集成HTTP测试数据收藏夹和Socket测试数据收藏夹以及Payload收藏夹",
          "支持测试标签页对收藏夹的HTTP、Socket、Payload数据的快速加载和切换",
        ],
        "statics": [
          require('../../assets/pageFive/static/HTTP抓包测试工具/P4-HTTP抓包测试工具-数据包-Payload收藏夹.png'),

        ],
      },
    ],
  },
  {
    "title": "HTTP模糊测试工具",
    titleIcon: require('../../assets/pageFive/HTTP模糊测试工具@2x.png'),
    titleIconStyle: {
      width: '1.7rem',
    },
    "titleImage": {
      "default": require('../../assets/pageFive/HTTP模糊测试工具-默认.png'),
      "hover": require('../../assets/pageFive/HTTP模糊测试工具-悬停.png'),
      "active": require('../../assets/pageFive/HTTP模糊测试工具-选中.png'),
    },
    "children": [
      {
        "intro": "工具简介",
        "desc": "HTTP模糊测试工具是一款跨平台的可配置的半自动化测试工具，可应用于各类功能测试和安全测试，如自动化SQL注入测试、XSS漏洞测试、资源探测、认证系统暴破、后台用户名和密码暴破、参数枚举、数据包重放攻击、缓冲区溢出、验证码识别、数据提取等。",
        "list": [
          "支持多种重放模式和变体赋值规则，满足各种测试需求",
          "独特的自定义测试流程功能，灵活应对多种场景",
          "详尽的测试结果数据展示，便于跟踪测试细节",
          "支持图片型和非图片型的验证码识别，支持验证码识别结果矫正及验证码的四则运算",
          "支持远程使用模式，支持远程模式下，不同用户共享HTTP模糊测试工具，支持只关闭界面不关闭服务模式",
        ],
        "statics": [
          require('../../assets/pageFive/static/HTTP模糊测试工具/P1-模糊测试工具-工具简介.png'),

        ],
      },
      {
        "intro": "多种重放模式和变体赋值规则",
        "desc": "支持多种重放模式，满足不同应用场景的测试需求。支持多种变体赋值方式以及多种变体值处理规则。",
        "list": [
          "支持5种数据包重放模式，支持各类畸形数据包的发送。",
          "支持10种变体赋值方式，14种变体值处理规则，集成大量各类测试所需的Payload。",
        ],
        "statics": [
          require('../../assets/pageFive/static/HTTP模糊测试工具/P2-模糊测试工具-多种重放模式和变体赋值规则.png'),

        ],
      },
      {
        "intro": "自定义模糊测试流程",
        "desc": "支持自定义测试流程，能够满足模糊测试数据发生前的登录、验证码识别、数据处理等需求，以及模糊测试数据发送之后的响应数据提取、处理、判断等需求。",
        "list": [
          "模糊测试数据包发送前后均可自定义测试流程",
          "支持请求发送、验证码识别、数据提取、数据处理等测试流程",
          "支持设置关键节点条件判断规则，根据判断规则对流程进行控制",
          "自定义流程提取的数据可作为变量插入到后续需要发送的测试数据中进行发送",
        ],
        "statics": [
          require('../../assets/pageFive/static/HTTP模糊测试工具/P3-模糊测试工具-自定义模糊测试流程.png'),

        ],
      },
      {
        "intro": "详尽的测试结果数据展示",
        "desc": "模糊测试会记录详细的测试流程中的各项数据，包括请求数据、响应数据、数据处理结果及条件判断结果等，以便用户更好分析测试结果、调试测试步骤。",
        "list": [
          "全面记录每轮测试流程中产生的数据",
          "可通过界面友好展现，便于观查和调试",
        ],
        "statics": [
          require('../../assets/pageFive/static/HTTP模糊测试工具/P4-模糊测试工具-详尽的测试结果数据展示.png'),

        ],
      },
    ],
  },
  {
    "title": "Web资源探测工具",
    titleIcon: require('../../assets/pageFive/Web资源探测工具@2x.png'),
    titleIconStyle: {
      width: '1.7rem',
    },
    "titleImage": {
      "default": require('../../assets/pageFive/Web资源探测工具-默认.png'),
      "hover": require('../../assets/pageFive/Web资源探测工具-悬停.png'),
      "active": require('../../assets/pageFive/Web资源探测工具-选中.png'),
    },
    "children": [
      {
        "intro": "工具简介",
        "desc": "Web资源探测工具是一款探测目标站点上存在的敏感资源的工具，用于寻找目标站点的突破口。Web资源探测工具以累积的庞大字典为基础，根据不同的字典组，以暴力的方式去猜测测试站点是否存在该Web资源，根据Web服务器的响应结果来判断该资源是否存在。",
        "list": [
          "独有的高级探测技术，支持启发式扫描模式，拥有自我学习和优化的机制",
          "支持GET和HEAD探测模式；集成高性能代理池，并支持代理轮换使用",
          "自动分析目标站点的脚本类型；自动识别和排除错误页面；自动跳过陷阱资源",
          "支持内置字典和外置字典，支持字典组，集成字典编辑器。支持字典的过滤和替换，支持资源路径过滤和结果过滤，支持任务历史记录",
          "支持独立远程Web服务部署，支持多用户共享使用，支持只关闭界面不关闭服务模式",
        ],
        "statics": [
          require('../../assets/pageFive/static/Web资源批量探测工具/1.png'),
        ],
      },
      {
        "intro": "智能分析自我优化",
        "desc": "能够自动分析脚本类型，选择合适的字典进行探测，同时能够识别和区分错误页面，自动检测和跳过陷阱资源。拥有自学习和优化能力，随着使用次数的积累，能够使探测工作更加快速准确。",
        "list": [
          "独创的启发式扫描技术",
          "自动分析目标站点的脚本类型",
          "自动识别和排除自定义错误页面",
          "自动检测和跳过陷阱资源",
          "拥有自我学习和优化的机制，随着使用次数的增多，它会越来越强大",
        ],
        "statics": [
          require('../../assets/pageFive/static/Web资源批量探测工具/2.png'),
        ],
      },
      {
        "intro": "多种自定义配置",
        "desc": "支持多种自定义设置选项，包括探测模式、字典设置及资源路径过滤等，可按用户需要进行调试，使用户使用得更加得心应手。",
        "list": [
          "支持GET和HEAD探测模式，可实时调整各种参数。集成高性能代理池，支持代理池代理轮换使用",
          "支持内置字典和外置字典，支持字典组，集成字典编辑器",
          "支持字典的过滤和替换，支持资源路径过滤和结果过滤",
          "支持远程模式使用，支持独立Web服务部署",
          "支持远程模式不同用户共享使用，支持只关闭界面不关闭服务模式",
        ],
        "statics": [
          require('../../assets/pageFive/static/Web资源批量探测工具/3.png'),
        ],
      },
    ],
  },
  {
    "title": "DNS带外工具箱",
    titleIcon: require('../../assets/pageFive/DNS带外工具箱@2x.png'),
    titleIconStyle: {
      width: '1.5rem',
    },
    "titleImage": {
      "default": require('../../assets/pageFive/DNS带外工具箱-默认.png'),
      "hover": require('../../assets/pageFive/DNS带外工具箱-悬停.png'),
      "active": require('../../assets/pageFive/DNS带外工具箱-选中.png'),
    },
    "children": [
      {
        "intro": "工具简介",
        "desc": "DNS带外工具箱是一款支持DNS以及HTTP的带外数据记录的工具,可绑定自定义域名，配置子域名HTTP响应内容，支持动态域名绑定及短网址。用于短网址、简易web站点搭建、XSS、SSRF、SQL注入等测试。",
        "list": [
          "支持以Web服务的形式部署和在线使用",
          "可作为漏洞验证等其他工具的扫描接口使用",
          "支持DNS以及HTTP的带外数据记录；支持动态域名绑定；支持短网址生成",
          "支持自定义子域名并配置HTTP响应数据",
          "支持配置子域名的多个子页面及子页面的响应数据",
        ],
        "statics": [
          require('../../assets/pageFive/static/DNS带外工具箱/P1-DNS带外工具箱-工具简介.png'),
        ],
      },
      {
        "intro": "部署简单，功能强大",
        "desc": "DNS带外工具箱支持独立部署，支持在线多人使用以及通过API在主框架界面上使用，满足多种使用场景。",
        "list": [
          "支持以Web服务的形式部署和在线使用",
          "可作为漏洞验证等其他工具的扫描接口使用",
          "支持DNS以及HTTP的带外数据记录；支持动态域名绑定；支持短网址生成",
          "支持自定义子域名并配置HTTP响应数据",
          "支持配置子域名的多个子页面及子页面的响应数据",
        ],
        "statics": [
          require('../../assets/pageFive/static/DNS带外工具箱/P2-DNS2.png'),
        ],
      },
    ],
  },
  {
    "title": "协同测试平台",
    titleIconStyle: {
      width: '1.3rem',
    },
    titleIcon: require('../../assets/pageFive/协同测试平台@2x.png'),
    "titleImage": {
      "default": require('../../assets/pageFive/协同测试平台-默认.png'),
      "hover": require('../../assets/pageFive/协同测试平台-悬停.png'),
      "active": require('../../assets/pageFive/协同测试平台-选中.png'),
    },
    "children": [
      {
        "intro": "工具简介",
        "desc": "协同测试平台是一套用于团队协作的任务系统，旨在提高测试任务中的团队效率，降低沟通成本。它分为服务端和客户端两个部分。管理员可在服务端对任务、人员进行分配和管理调度，实时跟踪任务状态。测试人员可以在客户端查看任务、推送任务数据，与其他成员实时共享数据。",
        "list": [
          "全局统筹，能够帮助管理员高效地管理人员、组织和任务",
          "团队协作，团队内可实时多维度查看测试任务进度和数据",
          "数据汇总，测试数据集中到服务端进行归档、分析、入库和多维度展示",
        ],
        "statics": [
          require('../../assets/pageFive/static/协同测试平台/P1-协同测试平台-工具简介.png'),
        ],
      },
      {
        "intro": "人员管理、任务统筹",
        "desc": "管理员能够在服务端对人员、任务进行统筹管理，实时查看任务状态，以保证任务有序进行。",
        "list": [
          "支持组织多级分组",
          "支持系统管理员、任务管理员、业务员多种角色，做到角色权限划分",
          "任务进度、到期时间实时展示，方便管理员对任务进行跟踪",
          "支持任务暂停、锁定等管理操作",
          "支持内网部署，适应于对安全性要求更高的企业",
        ],
        "statics": [
          require('../../assets/pageFive/static/协同测试平台/P2-协同测试平台-人员管理-任务统筹.png'),
        ],
      },
      {
        "intro": "团队协同，明确分工",
        "desc": "能够满足多人员协同任务的使用场景，支持任务分配、目标设置、任务计划设置等功能。任务成员之间能够实时看到各成员的任务进度和任务成果数据，降低成员间的沟通成本。",
        "list": [
          "支持子任务分配、目标分配，支持任务计划设置",
          "支持查看任务记录",
          "任务负责人支持下发队内通知",
        ],
        "statics": [
          require('../../assets/pageFive/static/协同测试平台/P3-协同测试平台 - 团队协同-明确分工.png'),
        ],
      },
      {
        "intro": "数据汇总，多维分析",
        "desc": "测试数据通过客户端推送至服务端进行清洗和解析和归档。测试数据能够以原始数据、目标数据等多种方式进行分类、汇总，并能够以时序图、关系图等多维度进行可视化展示。",
        "list": [
          "支持测试数据清洗、解析、归档及复盘展示",
          "支持任务时序图、关系图等多维度可视化查看任务数据",
          "支持在主框架通过提供的自定义界面挂载命令行工具，支持挂载工具的数据推送，支持插件化数据解析",
          "支持以插件的形式对接第三方工具推送的数据",
        ],
        "statics": [
          require('../../assets/pageFive/static/协同测试平台/P4-协同平台-数据汇总-多维分析.png'),
        ],
      },
    ],
  },
];
