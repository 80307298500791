import './index.less';
import {Image} from "../PageFive";
import {useEffect, useState} from "react";

const data = [
  require("../../assets/pageFour/目标数据.png"),
  require("../../assets/pageFour/关系图.png"),
  require("../../assets/pageFour/时序图.png"),
  require("../../assets/pageFour/多维度解析.png"),
];
export default function PageFour() {
  const [serialNum, setSerialNum] = useState(0);
  return (
    <div className='PageFour'>
      <div className='PageFour_inner'>
        <div className='left'>
          <Image src={data[serialNum]}/>
        </div>
        <div className='right'>
          <div
            data-activity={serialNum === 0}
            onClick={() => {
              setSerialNum(0);
            }}
          >
            {/*<Image src={require('../../assets/pageFour/目标数据-默认@2x.png')}/>*/}
          </div>
          <div
            data-activity={serialNum === 1}

            onClick={() => {
              setSerialNum(1);
            }}
          />
          <div
            data-activity={serialNum === 2}

            onClick={() => {
              setSerialNum(2);
            }}
          />
          <div
            data-activity={serialNum === 3}

            onClick={() => {
              setSerialNum(3);
            }}
          />
        </div>
      </div>
    </div>
  );
}
