import './downloadModal.less'
import React, {useEffect, useState} from 'react';
import winImg from '../../assets/pageOne/windows.png';
import linuxImg from '../../assets/pageOne/linux.png';
import macosImg from '../../assets/pageOne/macos.png';
import hint from '../../assets/pageOne/hint.png';
import message from "../message";
import {createPortal} from "react-dom";
import CourseButton from "../CourseButton";

const CloseIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
    <path id="形状_512_拷贝_2" data-name="形状 512 拷贝 2" fill='currentColor'
          d="M1518.56,220.761l-4.26,4.262-4.27-4.262-0.73.737,4.26,4.263-4.26,4.262,0.73,0.738,4.27-4.262,4.26,4.262,0.74-.738-4.26-4.262,4.26-4.263Z"
          transform="translate(-1509.31 -220.75)"/>
  </svg>
}

interface Item {
  version: string;
  size: string;
  platform: string;
}

const Modal = ({open, onClose}: any) => {
  const [check, setCheck] = useState(false);
  const [list, setList] = useState<Item[]>([]);

  useEffect(() => {
    if (open) {
      fetch('/official/versions')
        .then(response => response.json())
        .then(res => {
          if (res?.code === 200) {
            setList(res?.data || [])
          }
        })
    }
  }, [open])

  if (!open) {
    return null;
  }

  const handleDownload = (index: number) => {
    if (!check) {
      message.info('请先同意用户协议')
      return;
    }
    if (list.length && list[index]) {
      // 本地测试：由于跨域，可手动添加cookie
      // document.cookie = "exp=6bKRxXKl7WkDu2m0%2BYjRu8lv4Vs7wph2JShMXRn0h%2Bl002FYaJEW9jozpd7WL6uoJjV0H4E7rHmszDsLxozIF8FJe8ddknIJk0oaPQ%3D%3D;";

      fetch(`/official/download/stamp/${list[index].platform}/${list[index].version}`)
        .then(response => response.json())
        .then(res => {
          if (res?.code === 200 && res?.data) {
            window.open(`/official/download/${res.data}`, '_blank')
          } else {
            message.info(res?.msg || '获取下载链接失败')
          }
        })
      let platform = list[index].platform;
      if (platform === 'linux' || platform === 'mac') {
        platform = platform + '(x64)';
      }
      //@ts-ignore
      _paq?.push(['trackEvent', 'download', 'Click', `${platform}_download`]);
    }
  }
  return (
    createPortal((
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="modal-header">
            <div className='course'>
              <CourseButton/>
            </div>
            <div className="closeBtn" onClick={onClose}>
              <CloseIcon/>
            </div>
          </div>
          <ul>
            <li>
              <img src={winImg} alt="Windows"/>
              <div className="modal-info">
                <div className="platform-message">
                  <div className="name">
                    Windows
                  </div>
                  <div className="version">
                    版本：{list[0]?.version || '--'} ({list[0]?.size || '--'})
                  </div>
                </div>
                <div className="download-btn" onClick={() => handleDownload(0)}>
                  下载
                </div>
              </div>
            </li>
            <li className='multi'>
              <div>
                <img src={linuxImg} alt="Linux"/>
                <div className="modal-info">
                  <div className="platform-message">
                    <div className="name">
                      <span>
                        Linux
                      </span>
                      <span className='special'>x64<img src={hint} title='运行在64位x86架构（也被称作amd64或x86_64）上的Linux操作系统。例如Ubuntu、Fedora等发行版基本都是基于此架构。'/></span>
                    </div>
                    <div className="version">
                      版本：{list[1]?.version || '--'} ({list[1]?.size || '--'})
                    </div>
                  </div>
                  <div className="download-btn" onClick={() => handleDownload(1)}>
                    下载
                  </div>
                </div>
              </div>
              <div>
                <img src={linuxImg} alt="Linux"/>
                <div className="modal-info">
                  <div className="platform-message">
                    <div className="name">
                      <span>
                        Linux
                      </span>
                      <span className='special'>ARM<img src={hint} title='适用专为ARM架构设计的Linux操作系统。例如Ubuntu、Debian的ARM版本。'/></span>
                    </div>
                    <div className="version">
                      版本：{list[2]?.version || '--'} ({list[2]?.size || '--'})
                    </div>
                  </div>
                  <div className="download-btn" onClick={() => handleDownload(2)}>
                    下载
                  </div>
                </div>
              </div>

            </li>
            <li className='multi'>
              <div>
                <img src={macosImg} alt="MacOS" style={{width: 30, height: 36, marginLeft: 3}}/>
                <div className="modal-info">
                  <div className="platform-message">
                    <div className="name">
                      <span>MacOS</span>
                      <span className='special'>x64<img src={hint} title='适用基于Intel x86_64处理器的Mac电脑设计的操作系统，即使用intel芯片的Mac。'/></span>
                    </div>
                    <div className="version">
                      版本：{list[3]?.version || '--'} ({list[3]?.size || '--'})
                    </div>
                  </div>
                  <div className="download-btn" onClick={() => handleDownload(3)}>
                    下载
                  </div>
                </div>
              </div>
              <div>
                <img src={macosImg} alt="MacOS" style={{width: 30, height: 36, marginLeft: 3}}/>
                <div className="modal-info">
                  <div className="platform-message">
                    <div className="name">
                      <span>MacOS</span>
                      <span className='special'>ARM<img src={hint} title='适用苹果公司自研的基于M系列芯片的MacOS操作系统，即使用M1/M2芯片的Mac。'/></span>
                    </div>
                    <div className="version">
                      版本：{list[4]?.version || '--'} ({list[4]?.size || '--'})
                    </div>
                  </div>
                  <div className="download-btn" onClick={() => handleDownload(4)}>
                    下载
                  </div>
                </div>
              </div>

            </li>
          </ul>
          <div className='check-banner'>
            {!check ? <div className="no-check-agreement" onClick={() => setCheck(!check)}>
              </div> :
              <div className="check-agreement" onClick={() => setCheck(!check)}>
              </div>}
            <span>已仔细阅读并同意</span>
            <span onClick={() => {
              window.open('/#/agreement', '_blank')
            }}>《用户协议》</span>
          </div>
        </div>
      </div>
    ), document.body)

  );
};

export default Modal;
