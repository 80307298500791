import data1_line from "./json/data1_line.json";
import data2_line from "./json/data2_line.json";
import data3_line from "./json/data3_line.json";
import data4_line from "./json/data4_line.json";
import data5_line from "./json/data5_line.json";

export default [
  {
    name: '信息收集',
    line_w: data1_line.w,
    line_h: data1_line.h,
    description: '包含了10多种信息收集工具，帮助测试人员对测试目标进行全面的信息收集。各工具收集到的信息可在协同测试平台进行汇总和展示，为后续的安全测试奠定基础。',
    images: [
      require('../../assets/pageTwo/carousel/carousel (1).JPG'),
      require('../../assets/pageTwo/carousel/carousel (2).JPG'),
      require('../../assets/pageTwo/carousel/carousel (3).JPG'),
      require('../../assets/pageTwo/carousel/carousel (4).JPG'),
    ]
  },
  {
    name: '弱点扫描',
    line_w: data2_line.w,
    line_h: data2_line.h,
    description: '包含漏洞、敏感资源等脆弱点扫描相关的工具，帮助测试人员快速发现测试目标的安全风险，并详细提供验证流程、报告和修复建议。集成无代码可视化快速开发框架，简单极速定制出属于您的专属测试工具。',
    images: [
      require('../../assets/pageTwo/carousel/carousel (5).JPG'),
      require('../../assets/pageTwo/carousel/carousel (6).JPG'),
      require('../../assets/pageTwo/carousel/carousel (7).JPG'),
      require('../../assets/pageTwo/carousel/carousel (8).JPG'),
    ]
  },
  {
    name: '漏洞测试',
    line_w: data3_line.w,
    line_h: data3_line.h,
    description: '包含了HTTP抓包测试和HTTP模糊测试等工具，帮助测试人员实现测试目标的数据包拦截捕获、功能测试和模糊测试。全网首个支持流程自定义的模糊测试工具，集成了大量的模糊测试向量。',
    images: [
      require('../../assets/pageTwo/carousel/carousel (9).JPG'),
      require('../../assets/pageTwo/carousel/carousel (10).JPG'),
      require('../../assets/pageTwo/carousel/carousel (11).JPG'),
      require('../../assets/pageTwo/carousel/carousel (12).JPG'),
      require('../../assets/pageTwo/carousel/carousel (13).JPG'),
      require('../../assets/pageTwo/carousel/carousel (14).JPG'),
    ]
  },
  {
    name: '测试辅助',
    line_w: data4_line.w,
    line_h: data4_line.h,
    description: '包含了编码解码、加密解密、文本对比、正则表达式自动生成、DNS带外工具箱、高性能代理池等辅助模块。部分工具与其他工具数据推送联动，让测试更高效。',
    images: [
      require('../../assets/pageTwo/carousel/carousel (15).JPG'),
      require('../../assets/pageTwo/carousel/carousel (16).JPG'),
      require('../../assets/pageTwo/carousel/carousel (17).JPG')
    ]
  },
  {
    name: '更多工具',
    line_w: data5_line.w,
    line_h: data5_line.h,
    description: '包含了独立于框架使用的一些工具，可以下载后独立使用。集成了快捷工具箱，用户可以将第三方工具集成到快捷工具箱，还可以将命令行下执行的工具通过快捷提供的自定义界面模块接入到主框架，将数据对接到协同测试平台。',
    images: [
      require('../../assets/pageTwo/carousel/carousel (18).JPG'),
      require('../../assets/pageTwo/carousel/carousel (19).JPG'),
      require('../../assets/pageTwo/carousel/carousel (20).JPG'),
      require('../../assets/pageTwo/carousel/carousel (21).JPG'),
    ]
  },
]
