import './ApplyForModal.less';
import React, {useEffect, useMemo, useState} from 'react';
import {Image} from '../PageFive';
import message from "../message";

const CloseIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
    <path id="形状_512_拷贝_2" data-name="形状 512 拷贝 2" fill='currentColor'
          d="M1518.56,220.761l-4.26,4.262-4.27-4.262-0.73.737,4.26,4.263-4.26,4.262,0.73,0.738,4.27-4.262,4.26,4.262,0.74-.738-4.26-4.262,4.26-4.263Z"
          transform="translate(-1509.31 -220.75)"/>
  </svg>;
};
const defaultData = {name: '', email: '', phone: '', org: '', captcha: '', job: ''};

const Modal = ({open, onClose}: any) => {

  const [form, setForm] = useState(defaultData);

  const isSubmit = useMemo(() => {
    let a = JSON.parse(JSON.stringify(form))
    delete a.job
    return !Object.values(a).filter(item => !item).length;
  }, [form]);

  const [succeed, setSucceed] = useState(false);

  const [loading, setLoading] = useState(false);
  const [authCode, setAuthCode] = useState({data: null, id: null});

  useEffect(() => {
    setForm(defaultData);
    setSucceed(false);
    if (open) {
      getCaptcha();
    }
  }, [open]);

  function getCaptcha() {
    fetch('/official/captcha').then(response => response.json()).then(res => {
      console.log(res, 333333333);
      setAuthCode(res);
    });
  }

  if (!open) {
    return null;
  }

  return (
    <div className="modal-overlay ApplyForModal" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <div className="closeBtn" onClick={onClose}>
            <CloseIcon/>
          </div>
        </div>
        <div className='form'>
          {
            succeed ? (
              <div className='succeed'>
                <Image style={{width: '0.42rem', height: '0.42rem', marginTop: '0.37rem'}}
                       src={require('../../assets/pageSeven/ApplyForModal/成功.png')}/>
                <div style={{marginTop: '0.2rem'}}>申请提交成功</div>
                <div style={{marginTop: '0.11rem', color: 'rgba(255,255,255,0.5)'}}>感谢您的关注，我们将及时审核您的申请，请耐心等待！</div>
                <div className='btn' data-disabled="false" style={{width: '1.42rem', marginTop: '0.81rem'}}
                     onClick={onClose}>确定
                </div>
              </div>
            ) : (
              <>
                <div className='hint'>
                  尊敬的用户，专业版和企业版现仅面向于企业用户申请试用，需要以公司名义签订
                  试用协议。请按表单填写真实、完整的信息，以便我们尽快审核并与您取得联系
                </div>
                <div className='formItem'>
                  <div className='label'>姓名</div>
                  <div className='input'>
                    <input
                      placeholder='请输入您的姓名'
                      type="text"
                      value={form.name}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          name: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className='formItem'>
                  <div className='label'>邮箱</div>
                  <div className='input'>
                    <input
                      placeholder='请输入您的邮箱'
                      type="text"
                      value={form.email}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          email: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className='formItem'>
                  <div className='label'>联系方式</div>
                  <div className='input'>
                    <input
                      placeholder='请输入您的联系方式'
                      type="text"
                      value={form.phone}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          phone: e.target.value,
                        });
                      }}/>
                  </div>
                </div>
                <div className='formItem'>
                  <div className='label'>单位</div>
                  <div className='input'>
                    <input
                      placeholder='请输入您所在的单位'
                      type="text"
                      value={form.org}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          org: e.target.value,
                        });
                      }}/>
                  </div>
                </div>
                <div className='formItem'>
                  <div className='label' data-required="false">职位</div>
                  <div className='input'>
                    <input
                      placeholder='请输入您的职位'
                      type="text"
                      value={form.job}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          job: e.target.value,
                        });
                      }}/>
                  </div>
                </div>
                <div className='authCode'>
                  <div className='formItem'>
                    <div className='label'>验证码</div>
                    <div className='input'>
                      <input
                        placeholder='请输入验证码'
                        type="text"
                        value={form.captcha}
                        onChange={(e) => {
                          setForm({
                            ...form,
                            captcha: e.target.value,
                          });
                        }}/>
                    </div>
                  </div>
                  <Image
                    src={`data:image/jpg;base64,${authCode?.data}`}
                    className='authCode_img'
                    onClick={getCaptcha}
                  />
                </div>
                <div
                  className={['btn', isSubmit ? 'activity' : ''].join(' ')}
                  data-disabled={!isSubmit}
                  onClick={() => {
                    if (isSubmit) {
                      if (loading) return;
                      if (form.name.length > 45) {
                        message.info('姓名长度不超过45字');
                        return;
                      }
                      if (form.email.length > 200) {
                        message.info('邮箱长度不超过200字');
                        return;
                      }
                      if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(form.email)) {
                        message.info('请输入正确的邮箱');
                        return;
                      }
                      if (form.phone.length > 200) {
                        message.info('联系方式不超过200字');
                        return;
                      }
                      if (form.org.length > 45) {
                        message.info('单位名称长度不超过45字');
                        return;
                      }
                      setLoading(true);
                      fetch('/official/apply', {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                        },
                        //@ts-ignore
                        body: JSON.stringify({...form, captcha_id: authCode?.id, version: window?.version}),
                      }).then(response => response.json()).then(res => {
                        console.log(res, 2222222222222);
                        if (res?.code === 200) {
                          setSucceed(true);
                        } else {
                          return Promise.reject(res);
                        }
                      })
                        .catch(error => {
                          console.log(error, 1111111111);
                          message.info(error.msg || '服务器错误');
                        }).finally(() => {
                        setLoading(false);
                      }).finally(() => {
                        getCaptcha();
                      });
                    }
                  }}
                >
                  提交
                </div>
              </>
            )
          }

        </div>
      </div>
    </div>
  );
};

export default Modal;
