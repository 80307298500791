import React from 'react';
import './index.less';
import {createPortal} from "react-dom";

interface VideoModalProps {
  isOpen: boolean;
  onClose: () => void;
  src: string;
}

const VideoModal = ({isOpen, onClose, src}: VideoModalProps) => {
  if (!isOpen) return null;
  return (
    createPortal((
      <div className='video-modal'>
        <div className='video-modal-content'>
          <video controls autoPlay src={src} style={{width: '100%', height: '100%'}}>
            Your browser does not support the video tag.
          </video>
          <div onClick={onClose} className='video-modal-close'/>
        </div>
      </div>
    ), document.body)
  );
};

export default VideoModal;
