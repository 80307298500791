import React from "react";
import './index.less'

export default function CourseButton (){
  return(
    <div className='course_btn' onClick={()=>{
      window.open('https://tanggo.nosugar.tech/wiki')
    }}>
      <img src={require('../../assets/question_markIcon.png')} className='icon'/>
      <span>使用教程</span>
    </div>
  )
}
